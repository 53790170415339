
import {defineComponent, ref, computed, onMounted} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import SearchCard from "@/components/reservations/makereservation/search/SearchCard.vue";
import SummaryCard from "@/components/reservations/makereservation/summary/SummaryCard.vue";
import SearchResultCard from "@/components/reservations/makereservation/searchresult/SearchResultCard.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import App from "@/App.vue";

export default defineComponent({
    name: "Make-Reservation",
    components: {
        SearchCard,
        SummaryCard,
        SearchResultCard,
    },
    setup() {

        let refComp_SearchCard = ref(null);
        let refComp_SearchResultCard = ref(null);
        let refComp_SummaryCard = ref(null);

        //SearchList
        const  theFlightReservationList = ref({});

        onMounted(()=>{
            setCurrentPageBreadcrumbs("Rezervasyon", ["Arama", "Otel"]);
        });

        const dataFlightReservationSearchParams = computed(() => {
            //console.log("dataFlightReservationSearchParams START");
            const dataComputed = store.getters.getFlightReservationSearchParams;
            return dataComputed;
        });

        const dataFlightReservationList = computed(() => {
            const dataComputed = store.getters.getFlightReservationList;


            console.log("dataFlightReservationList "+dataComputed.SearchProcessID+" "+AppCore.prsCore_Flight.ccvSearchProcessID);
            //if (dataComputed.SearchProcessID === AppCore.prsCore_Flight.ccvSearchProcessID) {
                if (dataComputed!==undefined && dataComputed.data!==undefined) {
                    setSearchResult(dataComputed.data.searchCode, dataComputed.Type, dataComputed.data.voucherNo, dataComputed.data.result);
                }
                if (refComp_SearchCard.value!==undefined && refComp_SearchCard.value!==null) {
                    (refComp_SearchCard.value as any).searchFinished();
                }
            //}


            return dataComputed.data;
        });

        function searchTypeChanged(prmSearchType) {
            (refComp_SearchResultCard.value as any).setSearchType(prmSearchType);
            (document.getElementById("divSearchResult_Card") as HTMLElement) .style.display="none";
            (document.getElementById("divRow_MakeReservation_Master_SearchResultMain") as HTMLElement) .style.visibility="hidden";
        }

        function searchStarted(prmSearchType) {
            console.log("MakeReservation searchStarted START "+prmSearchType);
            (document.getElementById("divSearchResult_Card") as HTMLElement) .style.display="none";
            (document.getElementById("divRow_MakeReservation_Master_SearchResultMain") as HTMLElement) .style.visibility="hidden";
            (refComp_SearchResultCard.value as any).searchStarted(prmSearchType);
        }

        function setSearchResult(prmSearchCode, prmSearchType, prmVoucherNo, prmFlightReservationList) {
            console.log("MakeReservation.vue setSearchResult prmSearchCode: "+prmSearchCode+" prmSearchType: "+prmSearchType+" prmVoucherNo: "+prmVoucherNo+" AppCore.prsCore_Flight.ccvSearchProcessID: "+AppCore.prsCore_Flight.ccvSearchProcessID);
            store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, false);
            (document.getElementById("divSearchResult_Card") as HTMLElement) .style.display="";
            (document.getElementById("divRow_MakeReservation_Master_SearchResultMain") as HTMLElement).style.visibility="";
            AppCore.prsCore_Flight.setSearchCode(prmSearchCode, prmVoucherNo);
            theFlightReservationList.value = prmFlightReservationList;
            (refComp_SearchResultCard.value as any).setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList);
            (refComp_SummaryCard.value as any).setSearchResult(prmSearchCode, prmSearchType, prmFlightReservationList);
        }

        return {
            refComp_SearchCard, refComp_SearchResultCard, refComp_SummaryCard,
            dataFlightReservationSearchParams, dataFlightReservationList,
            searchTypeChanged,
            searchStarted,
        };
    }
});
